<template>
    <div :is="layout">
        <div class="pt-8">
            <form>
                <v-container>
                    <v-row v-if="!airport_img">
                        <v-col cols="12">
                            <div @dragover="dragover" @drop="dropImage"
                                 style="width: 500px; height: 281.25px; border: 1px dashed #797979; color: #797979; text-align: center; padding-top: 120px">
                                {{$t('airport.upload_img_hint')}}
                            </div>
                        </v-col>
                    </v-row>
<!--                    <v-row>-->
                    <v-row v-else>
                        <v-col cols="12">
                            <div class="airport_img_preview">
                                <img style="width: 100%; height: 100%" :src="airport_img" alt="">
                                <div @click="airport_img = ''" class="airport_img_delete">
                                    <i class="airport icon-shanchu" style="font-size: 48px; color: #fff;"></i>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.name"
                                    :label="$t('airport.Name')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.en_name"
                                    :label="$t('airport.EnName')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.ICAO"
                                    :label="$t('airport.ICAO')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.area"
                                    :label="$t('airport.Area')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.apron_number"
                                    :label="$t('airport.Apron_number')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.runway_number"
                                    :label="$t('airport.Runway_number')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.lat"
                                    :label="$t('airport.Lat')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.lng"
                                    :label="$t('airport.Lng')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.country"
                                    :label="$t('airport.Country')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.province"
                                    :label="$t('airport.Province')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.city"
                                    :label="$t('airport.City')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <!-- 编辑页面 -->
                        <v-col v-if="id" cols="12" md="4">
                            <v-select

                                    v-model="listData.country"
                                    :items="country"
                                    :label="$t('airport.Country')"
                                    @change="getCountryList"
                                    required
                            ></v-select>
                        </v-col>
                        <v-col v-if="id" cols="12" md="4">
                            <v-select
                                    v-model="listData.province"
                                    :items="province"
                                    :label="$t('airport.Province')"
                                    @change="getProvinceList"
                                    required
                                    hint="请先选择国家"
                                    persistent-hint
                            ></v-select>
                        </v-col>
                        <v-col v-if="id" cols="12" md="4">
                            <v-select
                                    v-model="listData.city"
                                    :items="city"
                                    :label="$t('airport.City')"
                                    hint="请先选择国家和省份"
                                    persistent-hint
                                    required
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.height"
                                    :label="$t('airport.Height')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                    v-model="listData.width"
                                    :label="$t('airport.Weight')"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                    v-model="listData.status"
                                    :items="items"
                                    item-text="state"
                                    item-value="abbr"
                                    :label="$t('airport.Status')"
                                    required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <div class="float-md-right mx-md-auto">
                        <v-btn color="accent" class="ml-4" @click="submit">{{$t('airport.Submit')}}</v-btn>
                        <v-btn color="secondary2" class="ml-4" @click="reset">{{$t('airport.Reset')}}</v-btn>
                    </div>
                </v-container>
            </form>
        </div>
        <v-dialog width='800px' v-model="dialog">
            <v-card>
                <div id="Map"></div>
            </v-card>
        </v-dialog>
        <v-btn class="float-btn" @click="dialog = true" fab color="accent">
            <v-icon>mdi-map</v-icon>
        </v-btn>
    </div>
</template>
<script>
    // import options from "./addressJson";
    import {add_info, edit_info, get_info} from "../../../api/airport";

    // import airportData from "../airportList/airportdata";
    import {get_secret_key} from "../../../api/cos";

    export default {
        // props: {
        //     id: [String, Number],
        //     thelat: [String, Number],
        //     thelng: [String, Number],
        //     theplace: [Array]
        // },
        data() {
            return {
                airport_img: '',
                id: '',
                // options,
                // editList: [],
                layout: null,
                thisEditData: [],
                // countryData: [],
                // provinceData: [],
                // country: [],
                // cityData: [],
                // city: [],
                // province: [],
                items: [
                    {state: "异常", abbr: 0},
                    {state: "正常", abbr: 1},
                ],
                listData: {
                    id: "",
                    name: "",
                    en_name: '',
                    area: "",
                    lat: "",
                    ICAO: '',
                    lng: "",
                    apron_number: "",
                    runway_number: "",
                    country: "",
                    province: "",
                    city: "",
                    height: "",
                    width: "",
                    status: "",
                },
                // index: "",
                map: '',
                // Lat: "",
                // Lng: "",
                place: [],
                // locations: [],
                dialog: false,
                marker: null
            };
        },
        methods: {
            dragover(e) {
                e.preventDefault()
            },
            dropImage(e) {
                e.preventDefault()
                if (e.dataTransfer.files.length <= 0 || e.dataTransfer.files.length > 1) {
                    this.$toast({
                        title: this.$t('setting.warning'),
                        message: '每次仅支持上传一张图片',
                        type: 'warning'
                    })
                    return
                }
                const file_obj = e.dataTransfer.files[0]
                const generate_random = (min_number, max_number) => Math.floor((max_number - min_number) * Math.random() + min_number)
                cos.putObject({
                    Bucket: 'cauc-atc-1255710621', /* 必须 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: `public_picture/airport/${this.getTime((new Date()).getTime() / 1000, "yyyy-MM-dd")}/${(new Date()).getTime()}${generate_random(10000000, 99999999)}.${file_obj.type.split('/')[file_obj.type.split('/').length - 1]}`,              /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: file_obj, // 上传文件对象
                    // onProgress: function (progressData) {
                    //     console.log(JSON.stringify(progressData));
                    // }
                }, (err, data) => {
                    this.airport_img = `https://${data.Location}`
                });
            },
            initMap() {
                this.map = new google.maps.Map(document.getElementById("Map"), {
                    zoom: 8,
                    center: {lat: 39.90366038315295, lng: 116.40662542765467},
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    disableDefaultUI: true,
                    gestureHandling: "greedy",
                    styles: this.$store.state.map_styles,
                });

                const geocoder = new google.maps.Geocoder();

                this.map.addListener("click", (e) => {
                    this.listData.lat = e.latLng.lat();
                    this.listData.lng = e.latLng.lng();
                    // 删除地图标记
                    if (this.marker) {
                        this.marker.setMap(null)
                    }
                    this.marker = new google.maps.Marker({
                        position: {lat: this.listData.lat, lng: this.listData.lng},
                        map: this.map,
                    });

                    geocoder.geocode(
                        {
                            location:
                                {
                                    lat: this.listData.lat,
                                    lng: this.listData.lng
                                }
                        },
                        (results, status) => {
                            if (status == google.maps.GeocoderStatus.OK) {
                                this.place = []
                                results[0].address_components.forEach((val, i) => {
                                    // 在点击一些地点时，会出现邮政编码，即'xx市''xx国''100011'
                                    if (val.long_name * 1) {
                                        results[0].address_components.splice(i, 1)
                                    } else {
                                        this.place.unshift(val.long_name)
                                    }

                                })
                                this.listData.country = this.place[0]
                                this.listData.province = this.place[1]
                                this.listData.city = this.place[2]
                            }
                        }
                    );

                });
            },
            getCountryList(value) {
                this.province = [];
                this.provinceData = [];
                for (let index in this.countryData) {
                    if (value == this.countryData[index].name) {
                        for (let e of this.countryData[index].childrens) {
                            this.provinceData.push(e);
                            this.province.push(e.name);
                        }
                    }
                }
            },
            getProvinceList(value) {
                this.city = [];
                this.cityData = [];
                for (let index in this.provinceData) {
                    if (value == this.provinceData[index].name) {
                        for (let e of this.provinceData[index].childrens) {
                            this.cityData.push(e);
                            this.city.push(e.name);
                        }
                    }
                }
            },
            submit() {
                // this.$router.push("/");
                this.listData.img = this.airport_img
                if (this.$route.name === 'airport-edit') {
                    edit_info(this.listData).then(res => {
                        this.$toast({
                            title: '成功',
                            message: '修改机场成功'
                        })
                        this.$router.push({name: 'airportList'})
                    })
                } else {
                    add_info(this.listData).then(res => {
                        this.$toast({
                            title: '成功',
                            message: '添加机场成功'
                        })
                        this.$router.push({name: 'airportList'})
                    })
                }

            },
            reset() {
                this.listData = {};
            },
        },

        mounted() {
            this.layout = this.$route.meta.use_layout
            if (this.$route.name === 'airport-edit') {
                get_info(this.$route.params?.id).then(res => {

                    this.airport_img = res['img']
                    for (let i in this.listData) {
                        this.listData[i] = res[i]
                        // console.log(i)
                    }

                    this.listData.id = res.id
                }).finally(() => {

                })
            }
        },
        watch: {
            'dialog'() {
                this.$nextTick(() => {
                    if (!this.map) {
                        this.initMap()
                    }
                })
            },
            '$store.state.map_styles'(v) {
                if (this.map) {
                    this.map.setOptions({
                        styles: v
                    })
                }
            },

        },
        components: {},
    };
</script>
<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl'
    #Map {
        width: 100%;
        height: 700px;
    }

    .airport_img_preview
        position relative
        width: 500px;
        height: 281.25px;
        border: 1px dashed #797979;
        color: #797979;
        text-align: center;
        overflow hidden

    .airport_img_delete
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        position: absolute;
        top: 0px;
        left: 0px;
        padding-top: 500px;
        transition: ease .2s all
        &:hover
            background: rgba(0, 0, 0, .5);
            padding-top: 100px;
            cursor pointer


</style>
